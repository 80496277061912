import API_CONSTANT from '../../constants/ApiConstant';
import { ASYNC_KEY } from '../../constants/AppConstant';
import { get, post, postWithOutToken } from '../../services/ApiServices';
import { setInAsyncStorage } from '../../utils';
import { setIsAuthenticated, setTokenData } from './Reducer';

const postLoginAPI = data => {
  return new Promise((resolve, reject) => {
    postWithOutToken(API_CONSTANT?.REQUEST_OTP, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const verifyOtpAPI = (data, dispatch) => {
  return new Promise((resolve, reject) => {
    postWithOutToken(API_CONSTANT.VERIFY_OTP, data)
      .then(async res => {
        await setInAsyncStorage(
          ASYNC_KEY.ACCESS_TOKEN,
          res?.data?.token?.access.replace(/\"/, '')
        );
        await setInAsyncStorage(
          ASYNC_KEY.REFRESH_TOKEN,
          res?.data?.token?.refresh.replace(/\"/, '')
        );
        await setInAsyncStorage(ASYNC_KEY.LOGIN_FLOW, res?.data?.redirect_uri);
        dispatch(
          setTokenData({
            [ASYNC_KEY.ACCESS_TOKEN]: res?.data?.token?.access.replace(
              /\"/,
              ''
            ),
            [ASYNC_KEY.REFRESH_TOKEN]: res?.data?.token?.refresh.replace(
              /\"/,
              ''
            )
          })
        );
        dispatch(setIsAuthenticated(true));
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getRandomUser = () => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.RANDOM_USERNAME}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getUserExists = query => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.USERNAME_EXISTS}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const postUser = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.USER, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getUser = (data, dispatch) => {
  return new Promise((resolve, reject) => {
    get(API_CONSTANT.USER, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getCompanyAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.COMPANY_LIST}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getJobTitleAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.JOB_TITLE_LIST}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getUniversityAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.UNIVERSITY_LIST}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getCommunitySubscrided = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.COMMUNITY_SUBSCRIBED}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getCommunityAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.COMMUNITY}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getJoinAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.JOIN_COMMUNITY, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getLeaveAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.LEAVE_COMMUNITY, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export {
  postLoginAPI,
  verifyOtpAPI,
  getRandomUser,
  getUserExists,
  postUser,
  getUser,
  getCompanyAPI,
  getJobTitleAPI,
  getUniversityAPI,
  getCommunityAPI,
  getCommunitySubscrided,
  getJoinAPI,
  getLeaveAPI
};
