/* eslint-disable quotes */
/* eslint-disable complexity */
import { ASYNC_KEY, TOAST_MESSAGE } from '../constants/AppConstant';
import {
  asyncClear,
  getFromAsync,
  removeFromAsync,
  setInAsyncStorage
} from './AsyncStorage';

import { ROUTE } from './Routes';

const urlQuery = (page, data) => page + JSON.stringify(data);

const getQueryParams = ({ data }) => JSON.parse(data);

const objAssign = (chk, obj, feild, val) => {
  chk && (obj[feild] = val);
  return obj;
};

const onLogout = router => {
  const temp = getFromAsync(ASYNC_KEY?.FREE_POST);
  asyncClear();
  setInAsyncStorage(ASYNC_KEY?.FREE_POST, temp);
  router?.replace('/home');
};

const changeBtnStyle = (chkCondation, val1, val2) =>
  chkCondation ? [val2, val1] : [val1, val2];

const hasValue = (chkCondation, val1, val2) => (chkCondation ? val1 : val2);

const selectedHasValue = (val1, val2, val3, val4) =>
  hasValue(val1, val1, val2 ? val4 : val3);

function likeCounter(num) {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [{ v: 1e3, s: 'K' }];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (
    (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
    si[index].s
  );
}

const salaryDiff = val => {
  val = (val / 100000).toFixed(1);
  return parseFloat(val) + 'L';
};

const showError = err => {
  console.error(err);
  if (
    err?.response?.data?.status_code === 500 ||
    err?.response?.status === 500
  ) {
    // toast.error(TOAST_MESSAGE.UNKNOW_ERROR);
  } else if (err?.response?.data?.status_code === 401 || err === 401) {
    //  toast.error(TOAST_MESSAGE.SESSION_TIMEOUT);
  } else {
    const errMessage =
      err?.response?.data?.message?.code ||
      err?.response?.data?.message?.message ||
      err?.response?.data?.message ||
      'Error Occurred';
    throw new Error(errMessage);
    // toast.error(errMessage);
  }
};

export const getError = err => {
  if (
    err?.response?.data?.status_code === 500 ||
    err?.response?.status === 500
  ) {
    return TOAST_MESSAGE.UNKNOW_ERROR;
  } else if (err?.response?.data?.status_code === 401 || err === 401) {
    //  toast.error(TOAST_MESSAGE.SESSION_TIMEOUT);
  } else {
    const errMessage =
      err?.response?.data?.message?.code ||
      err?.response?.data?.message?.message ||
      err?.response?.data?.message ||
      'Error Occurred';

    return errMessage;
  }
};

const trackEvent = (event, property) => {
  try {
    // mixpanel?.track(event, property);
  } catch (error) {
    Alert.alert('Error while getting Mixpanel track event properties.', error);
  }
};

const getMentions = text => {
  const retLines = text?.split('\n');
  let mentionUser = [];
  retLines?.forEach((retLine, index) => {
    const words = retLine.split(' ');
    words.map(x => {
      const check = x.charAt(0) === '@';
      if (check) {
        mentionUser.push(x);
      }
    });
  });
  return mentionUser;
};

const authRedirectUri = res => {
  if (res === REDIRECT_URI.USER_OTP_VERIFY) {
    return 'OtpScreen';
  } else if (res === REDIRECT_URI.SET_USER_NAME_SCREEN) {
    return 'SetUserNameScreen';
  } else if (res === REDIRECT_URI.SET_COMPANY_SCREEN) {
    return 'SetCompanyScreen';
  } else if (res === REDIRECT_URI.SET_FIELD_WORK_SCREEN) {
    return 'SetFieldWorkScreen';
  } else if (res === REDIRECT_URI.SET_BIO_SCREEN) {
    return 'SetBioScreen';
  } else if (res === REDIRECT_URI.SET_JOIN_COMMUNITY_SCREEN) {
    return 'JoinCommunity';
  } else if (res === REDIRECT_URI.APP_STACK) {
    return 'AppStack';
  } else if (res === REDIRECT_URI.SET_UNIVERSITY_SCREEN) {
    return 'SetUniversityScreen';
  } else if (res === REDIRECT_URI.USER_SUSPEND_SCREEN) {
    return 'UserBannedScreen';
  } else if (res === REDIRECT_URI.HOME_FEED) {
    return 'AppStack';
  }
};

const getScrollReady = (path, state, apiCall) => {
  Number(state.screenHeight[path]) + 50 > Number(state.scrollHeight[path]) &&
    apiCall();
};

const REDIRECT_URI = {
  LOGIN: 'LOGIN',
  USER_OTP_VERIFY: 'gvine://user_otp_verify/',
  SET_USER_NAME_SCREEN: 'gvine://SetUserNameScreen/',
  SET_COMPANY_SCREEN: 'gvine://SetCompanyScreen/',
  SET_FIELD_WORK_SCREEN: 'gvine://SetFieldWorkScreen/',
  SET_BIO_SCREEN: 'gvine://SetBioScreen/',
  SET_JOIN_COMMUNITY_SCREEN: 'gvine://JoinCommunity/',
  SET_UNIVERSITY_SCREEN: 'gvine://SetUniversityScreen/',
  USER_SUSPEND_SCREEN: 'gvine://user_suspended/',
  APP_STACK: 'AppStack',
  HOME_FEED: 'gvine://HomeFeed/'
};

export const formatStringInURL = text => {
  return text
    ?.replaceAll(' ', '-')
    ?.replaceAll("'", '')
    ?.replaceAll('&', '')
    ?.toLowerCase();
};

const companyNameInURL = company => {
  return formatStringInURL(company);
};

const communityNameInURL = community => {
  return formatStringInURL(community);
};

export function trimContentWithCompleteWord(content, maxLength = 155) {
  // Check if content exists and is not null
  if (!content) return content;

  // Strip markdown syntax and limit length
  const strippedContent = content
    .replace(/[#*_~`-]/g, '') // Remove common markdown symbols including hyphen
    .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1') // Replace links with just the text
    .replace(/!\[([^\]]+)\]\([^\)]+\)/g, '') // Remove images
    .replace(/\n/g, ' ') // Replace newlines with spaces
    .trim(); // Remove leading/trailing whitespace

  const description =
    strippedContent.length > 200
      ? strippedContent.substring(0, 197) + '...'
      : strippedContent;

  return description;
}

export function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for common mobile user agent strings
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    userAgent
  );
}

export function formatTitle(title) {
  if (!title) {
    return '';
  }

  title = title.replace(/[^a-zA-Z0-9 ]/g, '');

  title = title.trimEnd();

  // Step 1: Remove double spaces
  title = title.replace(/\s+/g, ' ');

  title = title.replace(/&/g, '');

  // Step 2: Replace spaces with hyphens
  title = title.replace(/ /g, '-');

  // Step 3: Ensure character limit is 200 without breaking words
  const words = title.split('-');
  let formattedTitle = '';
  for (let word of words) {
    if ((formattedTitle + '-' + word).length <= 200) {
      formattedTitle += (formattedTitle ? '-' : '') + word;
    } else {
      break;
    }
  }

  return formattedTitle;
}

export {
  ROUTE,
  getFromAsync,
  setInAsyncStorage,
  removeFromAsync,
  asyncClear,
  urlQuery,
  objAssign,
  getQueryParams,
  onLogout,
  changeBtnStyle,
  hasValue,
  selectedHasValue,
  likeCounter,
  showError,
  trackEvent,
  getMentions,
  authRedirectUri,
  REDIRECT_URI,
  salaryDiff,
  getScrollReady,
  companyNameInURL,
  communityNameInURL
  // generateQuery
};
