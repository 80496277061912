import icons from '../assets/leftSide';
import { colors } from '../themes';
import { hasValue } from '../utils';

const AppConstant = {
  LOGIN: 'Login',
  LOGIN_MESSAGE:
    'Your number is encrypted and cannot be tied to your username, we promise!',
  GRAPEVINE: 'PayTM',
  GRAPEVINE_CAPITAL: 'Grapevine',
  ATLASSIAN: 'Atlassian',
  ENTER_NUMBER: 'enter your number',
  ENTER_COMMENT: 'Your comment ',
  ENTER_REPLY: 'Your reply',
  TERM_CONDOTION_TEXT: 'By entering your number, you’re agreeing to our ',
  TERM_CONDITION: 'terms of service',
  AND_SIGN: ' & ',
  IN_CODE: '+91',
  HIGH_NOTIFICATION: '99+',
  DO_SOME_CHANGE: 'Do Some Change',
  PRIVACY_POLICY: 'Privacy policy',
  INVITE_COWORKER: 'Invite coworkers',
  CREATE_A_POST: 'Create a post',
  VERIFICATION_CODE: 'Verification code',
  Posts: 'posts',
  ADMIN: 'Admin',
  REGULAR: 'Regular',
  BRACKET_USERNAME: '{username}',
  OTP_MSG: 'Sent to ',
  OTP_TIME_MSG: 'Didn’t receive it? ',
  RESEND_OTP: 'Resend OTP',
  RESEND: ' Resend ',
  ENTER_OTP: 'Enter OTP',
  ENTER_EMAIL: 'Enter valid email',
  ENTER_CODE: 'enter code',
  NO_INTERNET: 'Not connected to the internet',
  AVOID_SPECIAL_CHAR: 'Avoid Special Character',
  NO_COMMUNITY: 'No Community',
  NO_NOTIFICATION: 'Feels empty,\nNo notifications, yet',
  NO_COMMENT: 'Feels empty,\nCreate a comment.',
  JOINED: 'Joined',
  JOIN_PLUS: '+ Join',
  SHARE: 'Share',
  SHARE_COMMUNITY: 'Share Community',
  SHARE_POST: 'Share Post',
  SHARE_COMMENT: 'Share Comment',
  REPLY: 'Reply',
  NEXT: 'Next',
  USER: 'User',
  USER_S: 'user',
  CREATE_COMMENT: 'Create comment',
  SEARCH_COMMUNITIES: 'Search communities',
  FIND_COMMUNITY: 'Find your community on grapevine ',
  TRENDING_COMMUNITIES: 'Trending communities',
  TRENDING_COMPANY: 'Trending in your company',
  COMMUNITY: 'Community',
  TRACK_COMMUNITY: 'community',
  TRACK_HOME: 'home',
  TRACK_PROFILE: 'profile',
  POST_TAG: 'Post Tag',
  SERIOUS: 'Serious',
  ADD_A_COMMENT: 'Add a comment',
  TYPING_A_COMMENT: 'Typing a comment',
  REPORT: 'Report',
  REPORT_POST: 'Report post',
  REPORT_COMMENT: 'Report comment',
  REPORT_USER: 'Report user',
  BLOCK: 'Block',
  BLOCK_USER: 'Block user',
  BAN: 'Ban',
  BOOST: 'Boost',
  DELETE: 'Delete',
  DELETE_POST: 'Delete post',
  DELETE_WARN: 'Delete & warn',
  CARD: 'Card',
  FEED: 'feed',
  MENU_REPORT_POST: 'report_post',
  MENU_REPORT_COMMENT: 'report_comment',
  MENUBAN: 'ban',
  MENUDELETE: 'delete',
  MENUDELETE_WARN: 'delete_and_warn',
  MENUBOOST: 'boost',
  MENU_REPORT_USER: 'report_user',
  MENU_BLOCK_USER: 'block_user',
  SWITCH_TO_CARD_VIEW: 'Switch to card view',
  SWITCH_TO_LIST_VIEW: 'Switch to list view',
  COMMUNITY_GUIDELINES: 'Community guidelines',
  SETTING: 'Settings',
  WELCOME_MSG: 'Join anonymous conversations with India’s \ntop',
  AMAZON_SERVICE: 'Software Engineers at Amazon',
  GOOGLE_SERVICE: 'Product Managers at Google',
  DELOITTE_SERVICE: 'Business analysts at Deloitte',
  PHONEPE_SERVICE: 'UX designers at Phonepe',
  ANY_MORE: 'and many more...',
  EDIT: 'Edit',
  LOGOUT_Text: 'Are you sure you want to logout of grapevine?',
  LOGOUT: 'Logout',
  NOTIFICATION: 'Notifications',
  INBOX: 'Inbox',
  SETUSERNAME_TEXT: 'Get a username that’s not your real name',
  FUN_CONVERSATION:
    'This lets you have candid and fun conversations on Grapevine',
  USERNAME: 'Username',
  ENTER_USERNAME: 'Please enter a username',
  ENTERUSERNAME: 'or enter a username',
  USERNAME_VALIDATION_MESSAGE_TOO_SHORT:
    'Please enter atleast 3 character for username',
  ENTER_ALERT_BIO: 'Enter Bio',
  ENTER_COMPANY: 'Search company name',
  SEARCH_UNIVERSITY: 'Search university name',
  EXAMPLES: 'Examples:',
  ADGUY: 'DrData',
  TECHBRO: 'Techbro',
  Chill_Panda: 'Chill_Panda',
  USERNAME_CONDITION:
    'Your username can contain only letters, numbers, and underscores',
  SAFE4WORK: 'Please keep your username safe for work',
  WELCOME_USERNAME: 'Welcome ',
  WHERE_WORK: '\nwhere do you work?',
  FIELD_WORK: 'What’s your field of work?',
  WHERE_STUDY: '\nwhere do you study?',
  NOT_VISIBLE: 'This information will not be visible on your profile',
  SEARCH_FIELDWORK: 'Search field of work',
  COMINGSOON_INDUSTRY: 'Grapevine will be coming soon for your industry!',
  NOTIFY_INDUSTRY:
    'We are working hard to bring grapevine to all industries. We’ll notify you once we ready to have you here. ',
  ENTER_BIO: 'Enter your bio',
  BIOTEXT: 240,
  BIO_DESCRIPTION:
    'Your bio gives you credibility in the community without revealing your identity.\n\nYou can share your role, industry, years of experience, hobbies and more!',
  BIO_DESCRIPTION_WEB:
    'Your bio gives you credibility in the community without revealing your identity.',
  INTRODUCE_YOURSELF: 'Introduce yourself',
  ATLEAST_3_COMMUNITY: 'Join atleast 5 communities',
  GRAPEVINE_DESC:
    'I’m a data scientist at PayTM. I spend time tracking stocks and read non-fiction',
  ATLASSIAN_DESC:
    'I’m a software engineer at Atlassian and a fitness enthusiast',
  SKIP: 'Skip',
  ACCOUNT_SUSPEND: 'Your account is suspended.',
  CONTENT_POLICY_INFO:
    'Dear user,\nYou have been blocked from accessing grapevine as a result of violating our',
  CONTENT_POLICY: ' content policy.',
  PLEASE_CONTACT: 'Please contact us at ',
  CONTACT_EMAIL: 'support@gvine.app',
  MODERATION_TEAM:
    ' if you feel this action is a result of an error from our moderation team.',
  TOAST_MSG: 'Something went wrong..',
  DARK_MODE: 'Dark mode',
  LIGHT_MODE: 'Light Mode',
  CONTACT_SUPPORT: 'Contact Support',
  TERMS_CONDITION: 'Terms & Conditions',
  OPEN_SOURCELICENCE: 'Open Source Licenses',
  APP_SETTING: 'Account Settings',
  DELETE_ACCOUNT: 'Delete Account',
  ENTER_RIGHT_NUMBER: 'Enter a valid phonenumber',
  CREATE_POST: 'Create post',
  POST: 'Post',
  POSTS: 'Posts',
  HOME: 'Home',
  SEARCH_POSTS: 'Looking for something?',
  SEARCH_OPTIONS: 'Search options',
  IN: 'In:',
  IN_S: 'in ',
  HAS: 'has:',
  AFTER: 'after:',
  BEFORE: 'before:',
  GIVE_YOUR_POST_TITLE: 'Give your post a title',
  DESC_TO_YOUR_TEXT_POST: 'Description to your text post',
  AD_TO_YOUR_POST: 'Select post type',
  VALID_LINK_TO_YOUR_POST: 'Valid Link to your post',
  ADD_OPTIONAL_DESCRIPTION: 'Add an optional description',
  ADD_OPTION: '+ Add option',
  OPTION: 'Option',
  ENTER_OPTION_TEXT: 'Please enter poll option',
  OPTION_LENGTH: 'You can not add poll options more than 6',
  ADD: 'Add',
  ADD_TAG: 'Add tag',
  POST_TO: 'Post to',
  TEXT: 'Text',
  IMAGE: 'Image',
  LINK: 'Link',
  POLL: 'Poll',
  MORE: 'More',
  MEET_NEW_MEMBER: 'Meet new members',
  LOADING: 'Loading',
  RETRY: 'Retry',
  EDIT_PROFILE: 'Edit profile',
  PROFILE: 'Profile',
  YOU: 'You',
  COMPANY: 'Company',
  JOB_TITLE: 'Job Title',
  UNIVERSITY: 'University',
  BIO: 'Bio',
  COMPANY_NAME: 'Company name',
  UNIVERSITY_NAME: 'University name',
  SAVE: 'Save',
  EXPIRES_IN: 'expires',
  PLEASE_SELECT_WHERE_DO_YOU_WORK: 'Please select where do you work',
  PLEASE_SELECT_YOUR_FIELD_OF_WORK: 'Please select your field of work',
  USER_NAME_ALREADY_EXISTS: 'Username already exists',
  USER_NAME_AVALIABLE: 'Username available',
  READ_MORE: 'Read More',
  READ_LESS: 'Read Less',
  CAN_NOT_FIND_COMPANY: 'Can’t find your company? + Add in a request',
  CAN_NOT_FIND_UNIVERSITY: 'Can’t find your university? + Add in a request',
  CAN_NOT_FIND_FIELD: 'Can’t find your field? + Add in a request',
  PLEASE_SELECT_ATLEAST_THREE_COMMUNITY: 'Please select atleast 5 community',
  ARE_YOU_STUDENT: 'Are you a student?',
  STUDENT: 'Student',
  ARE_YOU_A_PROFESSIONAL: 'Are you a professional?',
  PLEASE_CHECK_INTERNET: 'Please check internet',
  YOUR_COMMUNITIES: 'Your communities',
  COMMENTS: 'Comments',
  ALERT_SELECT_COMMUNITY: 'Please select community',
  ALERT_SELECT_POST_TITLE: 'Please enter post title',
  ALERT_ENTER_DESCRIPTION: 'Please enter description',
  ALERT_ENTER_LINK: 'Please enter link',
  ALERT_ENTER_VALID_LINK: 'Please enter a valid link',
  ALERT_SELECT_IMAGE: 'Please select image',
  IMAGE_SELECTION_INSTRUCTION: 'Tap to attach an image\n (Max 10MB)',
  ALERT_SELECTED_IMAGE_MB: 'Uploaded images should be less than 10 MB',
  UPLOAD_IMAGE_ERROR: 'Upload only image',
  IMAGE_PERMISSION_LABEL: 'User did not grant library permission.',
  CAMERA_PERMISSION_LABEL: 'User did not grant camera permission.',
  PLEASE_GRANT_PERMISSION: 'Please grant permission',
  REQUEST_PERMISSION:
    'We need access to your photos so we can create Image Post',
  OK: 'OK',
  YES: 'Yes',
  NO: 'No',
  WORKS: 'Works',
  CANCEL: 'Cancel',
  ASK_ME_LATER: 'Ask Me Later',
  NONE: 'None',
  LABEL_NO_COMMENT: 'No comment, yet.',
  LABEL_NO_POST_FOUND: 'No posts, yet.',
  APPLY: 'Apply',
  SEE_MORE_COMMENTS: 'See more comments',
  POST_DISCARD_ALERT:
    'Are you sure you want to discard your post? This action cannot be undone.',
  DISCARD: 'Discard',
  REPORT_POST_TEXT:
    'Thank you for keeping Grapevine safe. Please tap on next to have this post reviewed by a moderator.',
  REPORT_USER_TEXT:
    'Thank you for keeping Grapevine safe. Please tap on next to have this user reviewed by a moderator.',
  COMMENT_REPORT_TEXT:
    'Thank you for keeping Grapevine safe. Please tap on next to have this comment reviewed by a moderator.',
  DELETE_MSG:
    'Are you sure you want to delete your post? \n This action cannot be undone.',
  DELETE_COMMENT:
    'Are you sure you want to delete your comment? \n This action cannot be undone.',
  DELETE_ACCOUNT_MSG:
    'Are you sure you want to delete the account?\n\n(We will be deleting all the account related activity including profile, posts, comments in 30 days. This information cannot be retrieved later)',
  DELETE_WARN_POST:
    'Are you sure you want to delete & warn your post? This action cannot be undone.',
  DELETE_WARN_COMMENT:
    'Are you sure you want to delete & warn your comment? This action cannot be undone.',
  BAN_MSG:
    'Are you sure you want to ban this user? \n This action cannot be undone.',
  BLOCK_USER_MSG:
    'Are you sure you want to block this user? \n This action cannot be undone.',
  BOOST_POST_MSG:
    'Are you sure you want to boost your post? \n This action cannot be undone.',
  UPPERCASE_COMMENT: 'COMMENTS',
  PERMISSION_FOR_SAVE_IMAGE:
    'Grapevine needs permission to save images to your Photos',
  PERMISSION_RESPONSE: 'Access to photo library was denied',
  POPULAR: 'Popular',
  RECENT: 'Recent',
  SORT_BY: 'Sort by',
  SORT: 'Sort:',
  PLEASE_ENTER_COMMENT_TEXT: 'Please enter Comment text',
  POST_CREATOR: 'Post creator',
  LIKE: 'Like',
  COMMENT: 'Comment',
  COMMENTS: 'comments',
  CREATE: 'Create',
  GRAPEVINE_JOBS: 'GRAPEVINE JOBS',
  GET_HIRED_TITLE: 'Get hired at the most exciting companies',
  JOB_EXPERIENCE_TOP_PROFESIONAL: 'A job experience for top professionals',
  JOIN_THE_WAITLIST: 'Join the waitlist',
  // eslint-disable-next-line quotes
  THANKS_YOURE_ON_THE_LIST: "Thanks! You're on the list!",
  SHARE_NOW: 'Share Now',
  CREATE_NOW: 'Create Now',
  COMMENT_SHARE_MSG: 'comments on Grapevine',
  POST_SHARE_MSG: 'Check out this post on Grapevine - ',
  COMMUNITY_SHARE_MSG: 'Check out this community on Grapevine - ',
  SHARE_MSG:
    'Join anonymous conversations with thousands of engineers, PMs, Managers, Designers and more from top tech companies and startups',
  COMMENT_CREATED_SUSSFULLY: 'Comment Created Successfully',
  SCREEN_SHORT_PERMISSION:
    'We need access to read external storage access so we can create Image Post',
  GREPVINE_NEEDS_EXTERNAL_STORAGE:
    'Grapevine need Read External Storage Access',
  LOGIN_NOTICE_DESC:
    'OTPs help us make sure you’re real but your phonenumber isn’tstored.',
  LOGIN_NOTICE_DESC2:
    'Your number is hashed and salted converting your phone number into an unrecognisable string which is used to make sure its you',
  LOGIN_NOTICE_DESC3:
    'Not even our developers can identify who you are. You are safe even if our databases ever get leaked.',
  PROFILE_POSTS_COMMENTS: 'Profile',
  ICOGNITO_NOTICE: 'No one can know your real identity!',
  UPDATE_NOW: 'Update now',
  NO_THANKS: 'No, Thanks',
  UPDATE_MODAL_TITLE: 'Grapevine needs an update!',
  UPDATE_MODAL_DESC:
    'Upgrade to the latest version to get new features & improved performance!',
  RATING_MODAL_TITLE: 'Enjoying grapevine?',
  RATING_MODAL_DESC: 'Please rate your experience',
  RATING_MODAL_THANKS: 'Thank you!',
  RATING_MODAL_THANKS_DESC:
    'We’re glad you’re enjoying the app, would you like to rate us on the App store?',
  RATING_MODAL_RATE_US: 'Rate us',
  RATING_MODAL_GIVE_FEED_BACK: 'Give feedback',
  RATING_MODAL_FEED_BACK_TITLE: 'We’re listening',
  RATING_MODAL_FEED_BACK_TITLE_DESC:
    'Would you like to give us any feedback on making your experience better?',
  WHATS_NEW: 'What’s new?',
  DONE: 'Done',
  OFF: 'Off',
  ON: 'On',
  COMPANY_WELCOME_MSG: 'These posts are exclusive to members of ',
  INVITE_MEMBER_MSG: 'This community is exclusive to members of ',
  WE_NEED: 'We need',
  MORE_MEMBER: ' more members from your company to unlock your workplace!',
  COMMUNITY_COLLEAGUES:
    'Workplace is an exclusive & anonymous community for you and your colleagues.',
  SHARE_YOUR_COLLEAGUES: 'Share with your colleagues',
  VERIFY_YOUR_EMAIL: 'Verify your work email to get access',
  VERIFY_OTP: 'We’ll verify you with an OTP without saving your email',
  VERIFICATION_ENSURE: 'Verification ensures credibility in this community',
  VERIFIED_EMPLOYEE_ON: 'Verified employees on grapevine: ',
  CONTINUE: 'Continue',
  PROFILE_UPDATED: 'Profile updated',
  NOTIFICATION_ON: 'Notification On',
  NOTIFICATION_OFF: 'Notifications Off',
  GET_VERIFIED: 'Get verified',
  VERIFIED: 'Verified',
  WE_VERIFY_YOUR_WORK:
    'We’ll verify your work email without compromising on your identity',
  KEEP_GRAPEVINE_HONEST: 'Keep Grapevine honest',
  VERIFY_AUTHENTIC: 'Verifying makes Grapevine more authentic',
  GET_CREDIBILITY: 'Get credibility across Grapevine',
  GAIN_CREDIBILITY: 'Gain credibility on the platform',
  NO_COMPROMISE: 'No compromise on your identity',
  WE_DONT_STORE_EMAIL:
    'We don’t store emails. Just one code sent to verify you. This isn’t proof of using Grapevine',
  NOT_NOW: 'Not now',
  FIRST: 'first',
  SECOND: 'second',
  THREE: 'three',
  FOUR: 'four',
  NO_CONFIRMATION_EMAIL:
    'We don’t store emails. Just a code to verify you. No confirmation email.',
  ENTER_WORK_EMAIL: 'enter work email',
  GET_CODE: 'Get code',
  SUBMIT: 'Submit',
  DISMISS: 'Dismiss',
  SHARE_NUDGE_DESC:
    'Share this post with any one, even if they’re not on grapevine!',
  ENABLED: 'Enabled',
  DISABLED: 'disabled',
  SEARCH: 'Search',
  DISCOVER: 'Discover',
  JOBS: 'Jobs',
  GET_THE_GRAPEVINE_APP: 'Get the grapevine app!',
  GET_THE_GRAPEVINE_APP_NUDGE: 'Get the grapevine app',
  SCAN_THIS_QR_DOWNLOAD_APP: 'Scan this QR code to download the app',
  OR_FOLLLOW_THE_LINK_AT: 'or follow the link at',
  CHECK_OUT: 'Check out',
  TERMS: 'Terms',
  SUPPORT: 'Support',
  ALL_RIGHTS: 'Grapevine™ 2024, All rights reserved',
  TOP: 'Top',
  MONTH: 'Top of the month',
  WEEK: 'Top of the week',
  DELETE_AND_WARN: 'Delete & warn',
  TOP_COMMENTS: 'Top comments',
  COPYLINK: 'Copy link',
  BOOKMARK: 'Bookmark',
  VIEW_MORE: 'View More Comments',
  APP_STORE: 'Appstore',
  PLAY_STORE: 'Playstore',
  SCAN_CODE: 'Scan code',
  COPY_LINK: 'Copy link',
  FOLLOW_SYSTEM_SETTING: 'Follow system setting',
  CONTACT_US: 'Contact us',
  OPEN_SOURCE_LISENCES: 'Open source licenses',
  DANGER_ZONE: 'Danger zone',
  DELETE_YOUR_ACCOUNT: 'Delete your account',
  BLOCKED_ACCOUNTS: 'Blocked accounts',
  DRAG_DROP_IMAGE: 'Drag and drop an image or',
  UPLOAD: 'Upload',
  NO_ONE_CAN_KNOW: 'No one can know your real identity, we promise!',
  WHATSAPP_B: 'B',
  ORIGINAL: 'original',
  NESTED: 'nested',
  APP_NUDGE_TITLE: 'Read all comments on the Grapevine app',
  OPEN_APP_NUDGE_TITLE: 'Grapevine is best experienced on the app!',
  OPEN_APP: 'Open App',
  USE_APP: 'Use the app',
  LOGIN_TO_GRAPEVINE: 'Login to Grapevine',
  YOUR_NUM_IS:
    'Your number is encrypted and cannot be tied to your username, we promise!',
  INVALID_PHONE_NUMBER: 'Invalid phone number',
  BY_ENTERING_YOUR_NUM: 'By entering your number, you’re agreeing to our',
  PRIVACY_POLICY_: 'privacy policy.',
  JOIN_COMMUNITY: 'Join at least 5 communities',
  CAREER_FIELDS: 'Career fields',
  JOIN_ALL: 'Join all',
  WHERE_DO_YOU_WORK: 'Where do you work?',
  WHAT_YOUR_FIELD: 'What`s your field of work?',
  A_BRIEF_BIO: 'A brief bio',
  CHOOSE_A_USERNAME: 'Choose a username that’s not your real name',
  STAYING_ANONYMOUS:
    'Staying anonymous lets you have honest conversations on Grapevine',
  YOU_CAN_CHOOSE: 'You can choose one of these:',
  USERNAME_NOT_AVAILABLE: 'Username not available',
  USERNAME_AVAILABLE: 'Username available',
  SIGN_UP: 'Sign in',
  YOU_HAVE_NO_FREE_POST: 'You have no free posts for the day left',
  YOU_HAVE: 'You have',
  MORE_S: 'more',
  FREE_POST_FOR_DAY: 'free post for the day',
  BY: 'by',
  ON_S: ' on',
  FOOTER_TITLE:
    'Sign in to access thousands of posts and comments from top professionals on Grapevine',
  FOOTER_INFO:
    'You are in awesome company - there’s professionals from companies like Google, Meta, Amazon, Swiggy, Zomato and more!',
  FOOTER_TITLE_P: 'Sign in to access all posts and comments',
  FOOTER_INFO_P:
    'Sign in to access thousands of posts and comments from top professionals in your industry',
  JOIN_GRAPEVINE: 'Sign in',
  SIGN_UP_INFO:
    'Sign in to access thousands of posts and comments from top professionals in your industry',
  UNDER_CONSTRUCTION_TITLE: 'This page is under construction',
  CHECK_OUT_OUR_APP: 'Check out our app to get access to all our features!',
  VOTES: 'Votes',
  VOTE: 'Vote',
  USER_VERIFIED_EMAIL: 'User is verified',
  GET_CHAT_WITH: 'Get the app to chat with',
  YOU_CAN_EDIT: 'You can edit your profile on the app',
  OTHER: 'Other',
  NO_POSTS_YET: 'No posts, yet.',
  NO_COMMENT_YET: 'No comment, yet.',
  NO_BOOKMARK_YET: 'No bookmark, yet.',
  FEELS_EMPTY: 'Feels empty,',
  NO_NOTIFICATION_YET: 'No notifications, yet.',
  OF: 'of',
  GO_TO_HOME:
    'This page doesn’t exist. Try searching for something else or click Home',
  APP_EXCLUSIVE: 'Oops! app exclusive feature',
  GO_TO_HOME:
    'This page doesn’t exist. Try searching for something else or click Home',
  APP_EXCLUSIVE: 'Oops! app exclusive feature',
  PLEASE_STAY_COOL:
    'Please stay cool, the web feature coming very soon. Meanwhile, feel free to use our app to view full post.',
  TOTAL: 'Total',
  SEE_DETAILS: 'See details',
  FIXED: 'Fixed',
  VARIABLE: 'Variable',
  EQUITY: 'Equity',
  SALARIES: 'Salaries',
  SALARIES_HEADER_SUB_TITLE:
    'Discover & compare pay insights among top companies in India',
  SALARIES_PRIMARY_TAB: 'Salary distribution',
  SALARIES_SECONDARY_TAB: 'Salary progression',
  ANALYSE_SALARY: 'Analyse your salary',
  YOUR_SALARY_CARD: 'Your salary card',
  COMPARE: 'Compare',
  ANALYSE_SALARY_DESCRIPTION:
    'Create your salary card to get real-time insights based on role, location & experience. It shows you where you fit in among your peers, & helps track your career progress.',
  SAMPLE_SALARY_CARD: 'Here’s a sample card',
  SEE_SALARY_CARD: 'My salary card',
  SIGN_UP_TEXT: 'Sign Up',
  SIGN_IN: 'Sign in'
};

const TEXT_MENU_CHECK = {
  BAN: 'ban',
  BLOCK_USER: 'block_user',
  BOOST: 'boost',
  DELETE: 'delete',
  DELETE_AND_WARN: 'delete_and_warn',
  REPORT_POST: 'report_post',
  REPORT_USER: 'report_user',
  REPORT_COMMENT: 'report_comment'
};

const POST_TYPE = {
  ENGAGEMENT: 'engagement',
  LINK: 'link',
  IMAGE: 'image',
  POLL: 'poll',
  SALARY: 'salary',
  TEXT: 'text'
};

const SORT_TYPE = {
  POPULAR: 'Popular',
  NEWEST_FIRST: 'Newest first',
  OLDEST_FIRST: 'Oldest first'
};

const SORT_KEY = {
  POPULAR: 'popular',
  RECENT: 'recent',
  OLDEST: 'oldest'
};

const TEXT_MENU = {
  report_comment: {
    Icon: isLight => (
      <icons.blockUser
        color={hasValue(isLight, colors?.Neutral200, colors?.Neutral200Dark)}
      />
    ),
    Title: AppConstant.REPORT_COMMENT,
    color: isLight =>
      hasValue(isLight, colors?.Neutral200, colors?.Neutral200Dark)
  },
  ban: {
    Icon: isLight => (
      <icons.blockUser
        color={hasValue(isLight, colors?.Warning, colors?.WarningDark)}
      />
    ),
    Title: AppConstant.BAN,
    color: isLight =>
      hasValue(isLight, colors?.Neutral200, colors?.Neutral200Dark)
  },
  block_user: {
    Icon: isLight => (
      <icons.blockUser
        color={hasValue(isLight, colors?.Warning, colors?.WarningDark)}
      />
    ),
    Title: AppConstant.BLOCK_USER,
    color: isLight => hasValue(isLight, colors?.Warning, colors?.WarningDark)
  },
  boost: {
    Icon: isLight => (
      <icons.rocket
        color={hasValue(isLight, colors?.Neutral200, colors?.Neutral200Dark)}
      />
    ),
    Title: AppConstant.BOOST,
    color: isLight =>
      hasValue(isLight, colors?.Neutral200, colors?.Neutral200Dark)
  },
  delete: {
    Icon: isLight => (
      <icons.trash
        color={hasValue(isLight, colors?.Warning, colors?.WarningDark)}
      />
    ),
    Title: AppConstant.DELETE,
    color: isLight => hasValue(isLight, colors?.Warning, colors?.WarningDark)
  },
  delete_and_warn: {
    Icon: isLight => (
      <icons.trash
        color={hasValue(isLight, colors?.Warning, colors?.WarningDark)}
      />
    ),
    Title: AppConstant.DELETE_AND_WARN,
    color: isLight => hasValue(isLight, colors?.Warning, colors?.WarningDark)
  },
  report_post: {
    Icon: isLight => (
      <icons.reportPost
        color={hasValue(isLight, colors?.Neutral200, colors?.Neutral200Dark)}
      />
    ),
    Title: AppConstant.REPORT_POST,
    color: isLight =>
      hasValue(isLight, colors?.Neutral200, colors?.Neutral200Dark)
  },
  report_user: {
    Icon: isLight => (
      <icons.reportUser
        color={hasValue(isLight, colors?.Neutral200, colors?.Neutral200Dark)}
      />
    ),
    Title: AppConstant.REPORT_USER,
    color: isLight =>
      hasValue(isLight, colors?.Neutral200, colors?.Neutral200Dark)
  }
};

const IMAGE_CONSTANT = {
  CONTAIN: 'contain',
  CENTER: 'center',
  COVER: 'cover',
  STRETCH: 'stretch'
};

const LINKING = {
  PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.app.gvine',
  APP_STORE:
    'https://apps.apple.com/in/app/grapevine-for-professionals/id1641708426',
  COMMUNITY_GUIDELINES: 'https://gvine.app/guidelines',
  TERMS_CONDITION: 'https://gvine.app/terms',
  PRIVACY_POLICY: 'https://gvine.app/privacy',
  OPEN_SOURCE:
    'https://grapevine-chat.notion.site/Open-source-licenses-32b50e0743904f88ba4ce3c0f3cbe4f7',
  MAIL: 'support@gvine.app',
  SHARE_GRAPEVINE: 'https://share.gvine.app/home'
};

const ASYNC_KEY = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  ONLY_ONCE: 'only once',
  USER_DETAIL_SCREEN: 'user detail screen',
  THEME: 'theme',
  FEED_SELECTION: 'feed selection',
  APP_VERSION: 'app version',
  EVENT_ANALYTICS: 'event_analytics',
  SESSION_DATE_TIME: 'session_date_time',
  LOCAL_THEME: 'localTheme',
  FEED_TYPE: 'feedType',
  USER: 'user',
  LOGIN_FLOW: 'loginFlow',
  FREE_POST: 'freePost',
  USER_TAB_TYPE: 'userTabType',
  SEARCH_KEY: 'searchKey',
  NATIVE_WEBVIEW: 'isNativeWebView',
  HIDE_SALARY_HEADER: 'isHideSalaryHeader'
};

const THEME = {
  DARK_MODE: 'dark',
  LIGHT_MODE: 'light'
};

const TOAST_MESSAGE = {
  POST_DELETE_SUCCESS: 'Post deleted successfully',
  COMMENT_DELETE_SUCCESS: 'Comment deleted successfully',
  POST_DELETE_WARN_SUCCESS: 'Post deleted & warn successfully',
  POST_BOOST_SUCCESS: 'Post Boost Successfully',
  COMMENT_DELETE_WARN_SUCCESS: 'Comment Delete & Warn Successfully',
  POST_REPORT_SUCCESS: 'Post report successfully',
  USER_REPORT_SUCCESS: 'User report successfully',
  BLOCK_REPORT_SUCCESS: 'Block user successfully',
  COMMENT_REPORT_SUCCESS: 'Comment report successfully',
  POST_BAN_SUCCESS: 'User ban successfully',
  IMAGESAVE_SUCCESS: 'Image saved successfully',
  SESSION_TIMEOUT: 'Session Expired',
  DELETE_ACCOUNT_SUCCESS: 'We’ve recorded your request',
  UNKNOW_ERROR: 'Something went wrong at server side',
  COPY: 'Link Copied',
  SEND_OTP_MSG: 'An OTP has been sent to your number',
  POST_BOOKMARKED: 'Post bookmarked',
  POST_UNBOOKMARKED: 'Post unbookmarked'
};

const FEED_SELECT_TYPE = {
  POPULAR: 'popular',
  RECENT: 'recent',
  TOP_OF_THE_WEEK: 'top_of_the_week',
  TOP_OF_THE_MONTH: 'top_of_the_month'
};

const PROFILE_SELECTED_TAB = {
  POSTS: 'ProfilePosts',
  COMMENTS: 'ProfileComments'
};

const MAIL_TO = 'mailto:support@gvine.app?subject=Feedback';

const POST_FOLLOW = {
  FOLLOW: 'FOLLOW',
  UNFOLLOW: 'UNFOLLOW'
};

const TAB_NAME = {
  POSTS: 'Posts',
  COMMENTS: 'Comments',
  BOOKMARKS: 'Bookmarks'
};

const PROFILE_TABS = [
  {
    id: 1,
    name: 'Posts'
  },
  {
    id: 2,
    name: 'Comments'
  },
  {
    id: 3,
    name: 'Bookmarks'
  }
];

const TYPE = {
  REPLY: 'reply',
  C_REPLY: 'c_reply',
  BIG: 'big',
  SMALL: 'small',
  MAIN: 'main'
};

export const assetConstant = {
  communityBgLight:
    'https://stagvinecip01.blob.core.windows.net/stc-gvine-blob-centralindia-prod-001-django/images/default_assets/default_community_background-light.png',
  companyLogo:
    'https://stagvinecip01.blob.core.windows.net/stc-gvine-blob-centralindia-prod-001-django/images/default_assets/default_company_logo.png',
  communityBgDark:
    'https://stagvinecip01.blob.core.windows.net/stc-gvine-blob-centralindia-prod-001-django/images/default_assets/default_community_background-dark.png'
};

export {
  AppConstant,
  TEXT_MENU,
  TEXT_MENU_CHECK,
  IMAGE_CONSTANT,
  LINKING,
  ASYNC_KEY,
  THEME,
  TOAST_MESSAGE,
  // FEED_SELECTION_ARR,
  FEED_SELECT_TYPE,
  PROFILE_SELECTED_TAB,
  MAIL_TO,
  POST_FOLLOW,
  TYPE,
  POST_TYPE,
  SORT_TYPE,
  SORT_KEY,
  PROFILE_TABS,
  TAB_NAME
};
