/* eslint-disable complexity */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { colors } from '../../themes';
import { useRouter } from 'next/router';
import { AppContext } from '../../context/AppProvider';
import {
  showError,
  REDIRECT_URI,
  getFromAsync,
  onLogout,
  setInAsyncStorage,
  ROUTE
} from '../../utils';
import {
  AppConstant,
  ASYNC_KEY,
  FEED_SELECT_TYPE,
  TOAST_MESSAGE
} from '../../constants/AppConstant';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
  postLoginAPI,
  verifyOtpAPI,
  getRandomUser,
  getUserExists,
  postUser,
  getUser
} from '../../store/login/Action';
import { setPostData } from '../../store/home/Reducer';
import RenderHeader from './RenderHeader';
import {
  Auth,
  Otp,
  User,
  Company,
  University,
  Introduce,
  Career,
  FieldWork
} from '.';
import * as track from '../../utils/MixpanelTrackEvent';
import { AxiosError } from 'axios';

const btnColor = {
  backgroundImage: `linear-gradient(${86.48}deg, ${colors?.linearGr}, ${
    colors?.linearGrDark
  })`
};

const LoginModal = ({ loginModal, closeModal }) => {
  const router = useRouter();
  const context = useContext(AppContext);
  const dispatch = useDispatch();

  const [screenRender, setScreenRenderState] = useState([REDIRECT_URI?.LOGIN]);
  const [IsLight, setIsLight] = useState(false);
  const [phNo, setPhNo] = useState('');
  const [invalidPhNo, setInvalidPhNo] = useState(false);
  const [otp, setOtp] = useState('');
  const [intro, setIntro] = useState('');

  const [randomUserList, setRandomUserList] = useState([]);
  const [writeUserName, setWriteUserName] = useState('');
  const [writeCompany, setWriteCompany] = useState('');
  const [writeFieldWork, setWriteFieldWork] = useState('');
  const [writeUniversity, setWriteUniversity] = useState('');
  const [checkUserName, setCheckUserName] = useState(true);

  const matches = useMediaQuery('(min-width:600px)');

  const _randomUser = useCallback(() => {
    getRandomUser(null, dispatch)
      .then(res => {
        setRandomUserList(res?.data?.data?.usernames);
      })
      .catch(err => {
        showError(err);
      });
  }, [dispatch]);

  useEffect(() => {
    let tokenData = getFromAsync(ASYNC_KEY.ACCESS_TOKEN);
    let userName = getFromAsync(ASYNC_KEY.USER);
    if (tokenData && !userName) {
      _randomUser();
    }
  }, [_randomUser, screenRender]);

  const setScreenRender = value =>
    value ? setScreenRenderState(e => [value, ...e]) : setScreenRenderState([]);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
  }, [context?.theme, checkUserName, context?.themes]);

  const sentOtpFun = num => {
    setInvalidPhNo(false);
    const phoneNoObj = {
      phone_number: AppConstant.IN_CODE + (num === undefined ? phNo : num)
    };

    return postLoginAPI(phoneNoObj, dispatch)
      .then(res => {
        if (res?.data?.status) {
          const redirectFlow = res?.data?.redirect_uri;
          toast.success(TOAST_MESSAGE?.SEND_OTP_MSG);
          setScreenRender(redirectFlow);
        } else {
          setInvalidPhNo(true);
        }
      })
      .catch(err => {
        setInvalidPhNo(false);
        showError(err);
      });
  };

  const verifyOtpFun = num => {
    const otpObj = {
      phone_number: AppConstant.IN_CODE + phNo,
      otp: num === undefined ? otp : num
    };
    verifyOtpAPI(otpObj, dispatch)
      .then(res => {
        if (res?.data?.status) {
          dispatch(setPostData([]));
          const redirectFlow = res?.data?.redirect_uri;
          const token = res?.data?.token?.access.replace(/\"/, '');
          setScreenRender(redirectFlow);
          context.setToken(token);
          _user();
          if (redirectFlow === REDIRECT_URI.HOME_FEED) {
            router?.reload();
            _onClose();
          }
        } else {
          toast.error('OTP verification failed. Please try again.');
        }
      })
      .catch(err => {
        showError(err);
      });
  };

  const _user = () => {
    getUser(null, dispatch)
      .then(res => {
        setWriteUserName(res?.data?.data?.username);
      })
      .catch(err => {
        showError(err);
      });
  };

  const _userExists = () => {
    getUserExists(writeUserName)
      .then(res => {
        if (res?.data?.data?.exists) {
          setCheckUserName(res?.data?.data?.exists);
        } else {
          _userAPI({ username: writeUserName });
          setCheckUserName(res?.data?.data?.exists);
        }
      })
      .catch(err => {
        showError(err);
      });
  };

  const _userAPI = data => {
    postUser(dispatch, data)
      .then(res => {
        let redirectFlow = res?.data?.redirect_uri;
        setInAsyncStorage(ASYNC_KEY.LOGIN_FLOW, redirectFlow);
        setScreenRender(redirectFlow);
        if (redirectFlow === REDIRECT_URI.HOME_FEED) {
          router?.reload();
          _onClose();
        }
      })
      .catch(err => {
        showError(err);
      });
  };

  const _onClose = () => {
    closeModal();

    !router?.pathname.startsWith(ROUTE?.POST_DETAILS) && context.setToken(null);
    setScreenRenderState([REDIRECT_URI?.LOGIN]);
    setPhNo('');
    setInvalidPhNo('');
    setOtp('');
    setIntro('');
    setRandomUserList([]);
    setWriteUserName('');
    setWriteCompany('');
    setWriteFieldWork('');
    setWriteUniversity('');
    setCheckUserName(false);
  };

  return (
    <Dialog
      open={loginModal}
      PaperProps={{
        style: { borderRadius: 10 }
      }}
      style={{ backdropFilter: 'blur(5px)' }}
      fullScreen={matches ? false : true}
      height={300}
      onClose={() => {
        if (!router?.pathname.startsWith(ROUTE?.POST_DETAILS)) {
          dispatch(setPostData([]));
          setInAsyncStorage(ASYNC_KEY?.FEED_TYPE, FEED_SELECT_TYPE.POPULAR);
          context.setToken(null);
          onLogout(router);
          _onClose();
        }
      }}
    >
      <div
        className={` ${
          matches ? 'w-[407px]' : 'w-auto h-full'
        }  bg-White dark:bg-DarkBg border-[1px] border-Neutral400 dark:border-Neutral400Dark rounded-[12px]`}
      >
        <RenderHeader
          screen={screenRender}
          back={() => {
            const newState = [...screenRender];
            newState.shift();
            setScreenRenderState(newState);
          }}
          closeModal={() => {
            _onClose();
          }}
        />
        {/* ----------------------------login----------------------------- */}
        {screenRender[0] === REDIRECT_URI?.LOGIN && (
          <Auth
            btnColor={btnColor}
            value={phNo}
            invalidPhNo={invalidPhNo}
            onChange={e => {
              setPhNo(e?.target?.value);
              if (e?.target?.value?.length === 10) {
                sentOtpFun(e?.target?.value);
                track.trackLoginSubmit();
              }
            }}
            // onKeyDownCapture={e => e.key === 'Enter' && sentOtpFun()}
            onClick={() => {
              sentOtpFun();
              track.trackLoginSubmit();
            }}
          />
        )}
        {/* ----------------------------otp----------------------------- */}
        {screenRender[0] === REDIRECT_URI?.USER_OTP_VERIFY && (
          <Otp
            phNo={phNo}
            btnColor={btnColor}
            value={otp}
            loginAPi={sentOtpFun}
            onChange={e => {
              setOtp(e?.target?.value);
              if (e?.target?.value?.length === 6) {
                verifyOtpFun(e?.target?.value);
                track.trackOtpSubmitTap();
              }
            }}
            // onKeyDownCapture={e => e.key === 'Enter' && verifyOtpFun()}
            onClick={() => {
              verifyOtpFun();
              track.trackOtpSubmitTap();
            }}
          />
        )}
        {/* ----------------------------user----------------------------- */}
        {screenRender[0] === REDIRECT_URI?.SET_USER_NAME_SCREEN && (
          <User
            randomUser={randomUserList}
            value={writeUserName}
            checkUserName={checkUserName}
            onSelectUser={e => {
              setWriteUserName(e);
            }}
            onChange={e => setWriteUserName(e?.target?.value)}
            onClick={() => {
              _userExists();
              track.trackSetUsernameSubmitTap(writeUserName);
            }}
          />
        )}
        {/* ----------------------------company----------------------------- */}
        {screenRender[0] === REDIRECT_URI?.SET_COMPANY_SCREEN && (
          <Company
            username={writeUserName}
            company={val => {
              setWriteCompany(val);
              _userAPI({ company: val?.id });
              track.trackSetCompanynameSubmitTap(val?.display_name);
            }}
            AreYouClick={() => {
              setScreenRender(REDIRECT_URI?.SET_UNIVERSITY_SCREEN);
            }}
            onClick={() => {
              _userAPI({ company: writeCompany?.id });
              track.trackSetCompanynameSubmitTap(writeCompany?.display_name);
            }}
          />
        )}
        {/* ----------------------------field work----------------------------- */}
        {screenRender[0] === REDIRECT_URI?.SET_FIELD_WORK_SCREEN && (
          <FieldWork
            fieldWork={val => {
              setWriteFieldWork(val);
              _userAPI({ job_title: val?.id });
              track.trackSetFieldWorkSubmitTap(val?.name);
            }}
            onClick={() => {
              _userAPI({ job_title: writeFieldWork?.id });
              track.trackSetFieldWorkSubmitTap(writeFieldWork?.name);
            }}
          />
        )}
        {/* ----------------------------university----------------------------- */}
        {screenRender[0] === REDIRECT_URI?.SET_UNIVERSITY_SCREEN && (
          <University
            username={writeUserName}
            university={val => {
              setWriteUniversity(val);
              _userAPI({ university: val?.id });
            }}
            AreYouClick={() => {
              setScreenRender(REDIRECT_URI?.SET_COMPANY_SCREEN);
            }}
            onClick={() => {
              _userAPI({ university: writeUniversity?.id });
            }}
          />
        )}
        {/* ----------------------------introduction----------------------------- */}
        {screenRender[0] === REDIRECT_URI?.SET_BIO_SCREEN && (
          <Introduce
            skip={() => {
              _userAPI({ bio: '' });
              track.trackSetBioSkipTap();
            }}
            value={intro}
            onChange={e => setIntro(e?.target?.value)}
            onClick={() => {
              _userAPI({ bio: intro });
              track.trackSetBioSubmitTap(intro);
            }}
          />
        )}
        {/* ----------------------------university----------------------------- */}
        {screenRender[0] === REDIRECT_URI?.SET_JOIN_COMMUNITY_SCREEN && (
          <Career />
        )}
      </div>
    </Dialog>
  );
};

export default LoginModal;
